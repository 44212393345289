/**
 * @file Helpers for attachments
 */

import device from '@@/bits/device'
import { __ } from '@@/bits/intl'
import { secondsToSemiColonedText } from '@@/bits/time'
import { getHostname } from '@@/bits/url'
import { isAttachmentSupportedByZoom } from '@@/bits/zoom'
import type { PostColor } from '@@/types'
import type { BeethovenDisplayAttributes } from '@padlet/beethoven-client'
import { LinkHelpers } from '@padlet/beethoven-client'
import type { BeethovenContentCategory, BeethovenLinkForValidation } from '@padlet/beethoven-client/src/types'

export const BLANK_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='

export enum FallbackAttachmentType {
  Audio,
  Video,
  BrokenLink,
  BrokenPadletLink,
  File,
  GoogleDocument,
  Image,
  Link,
  Presentation,
  PrivateDocument,
  Spreadsheet,
  TextDocument,
}

const fallbackAttachmentTypeMapping: Record<FallbackAttachmentType, string> = {
  [FallbackAttachmentType.Audio]: 'audio',
  [FallbackAttachmentType.Video]: 'video',
  [FallbackAttachmentType.BrokenLink]: 'broken-link',
  [FallbackAttachmentType.BrokenPadletLink]: 'broken-padlet-link',
  [FallbackAttachmentType.File]: 'file',
  [FallbackAttachmentType.GoogleDocument]: 'google-document',
  [FallbackAttachmentType.Image]: 'image',
  [FallbackAttachmentType.Link]: 'link',
  [FallbackAttachmentType.Presentation]: 'presentation',
  [FallbackAttachmentType.PrivateDocument]: 'private-document',
  [FallbackAttachmentType.Spreadsheet]: 'spreadsheet',
  [FallbackAttachmentType.TextDocument]: 'text-document',
}

export const fallbackAttachmentSizeMapping: Record<FallbackAttachmentType, { width; height }> = {
  [FallbackAttachmentType.Audio]: { width: 1032, height: 1032 },
  [FallbackAttachmentType.Video]: { width: 1032, height: 1032 },
  [FallbackAttachmentType.BrokenLink]: { width: 1032, height: 516 },
  [FallbackAttachmentType.BrokenPadletLink]: { width: 1032, height: 516 },
  [FallbackAttachmentType.File]: { width: 1032, height: 516 },
  [FallbackAttachmentType.GoogleDocument]: { width: 1032, height: 516 },
  [FallbackAttachmentType.Image]: { width: 1032, height: 516 },
  [FallbackAttachmentType.Link]: { width: 1032, height: 516 },
  [FallbackAttachmentType.Presentation]: { width: 1032, height: 516 },
  [FallbackAttachmentType.PrivateDocument]: { width: 1032, height: 516 },
  [FallbackAttachmentType.Spreadsheet]: { width: 1032, height: 516 },
  [FallbackAttachmentType.TextDocument]: { width: 1032, height: 516 },
}

export const LOWERCASE_PROVIDER_NAMES = {
  YOUTUBE: ['youtube', 'youtu.be'],
}

export function isAttachmentBlocked(
  displayAttributes: BeethovenDisplayAttributes,
  blockedContentProviders: Set<string>,
): boolean {
  const lowercaseProviderName = displayAttributes.provider_name?.toLowerCase()
  if (blockedContentProviders.has('youtube') && LOWERCASE_PROVIDER_NAMES.YOUTUBE.includes(lowercaseProviderName)) {
    return true
  }
  return false
}

/**
 * Proxy through Cloudflare worker which adds appropriate headers so that
 * file at `url` is downloaded instead of opened in the browser.
 **/
export function downloadUrl(url: string): string {
  const encodedUrl: string = encodeURIComponent(url)
  return `https://padletcdn.com/cgi/fetch?disposition=attachment&url=${encodedUrl}`
}

// When the browser can't render an opened attachment, it will download the file, we should use downloadUrl to preserve the original file name when it contains unicode characters
export function urlForOpening(linkData: BeethovenDisplayAttributes | null, displayUrl?: string): string {
  if (!displayUrl) {
    return ''
  }

  if (linkData == null) {
    return displayUrl
  }

  const isFile = linkData.content_category === 'file'
  const isDocument = linkData.content_category === 'document'
  const isUpload = linkData.provider_name.toLowerCase() === 'padlet drive'

  if ((isFile || isDocument) && isUpload && !linkData.embed_code) {
    return downloadUrl(displayUrl)
  }

  return displayUrl
}

export function getStylizedProviderName(name: string | null | undefined): string {
  if (!name) return ''
  // This is done to comply with Youtube's requirements in order to use their player on Padlet.
  if (name.toLowerCase() === 'youtube') return name
  return name.toLowerCase()
}

export function fallbackImageUrl(
  type: FallbackAttachmentType,
  colorScheme: 'light' | 'dark',
  color: PostColor,
): string {
  const typeString = fallbackAttachmentTypeMapping[type]
  const colorString = color || 'gray'
  return `https://padlet.net/beethoven/fallbacks/220105/${typeString}/${colorScheme}-${colorString}.png`
}

export function getMetadataText(link: BeethovenDisplayAttributes): string {
  if (LinkHelpers.isPadletWall(link)) return __('Padlet')
  if (LinkHelpers.isSlideshow(link)) return __('Slideshow')
  if (LinkHelpers.isSpotify(link)) return getStylizedProviderName(link.provider_name)
  if (LinkHelpers.isYoutube(link)) return getStylizedProviderName(link.provider_name)
  if (LinkHelpers.isAudio(link)) return __('Audio')
  if (LinkHelpers.isUploadedVideo(link)) return __('Video')
  if (LinkHelpers.isPhoto(link)) return __('Image')
  if (LinkHelpers.isPage(link)) {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return getHostname(link.url).replace(/^www\./, '') || getStylizedProviderName(link.provider_name) || __('webpage')
  }
  if (LinkHelpers.isFile(link) && link.metadata?.pretty_size != null) return link.extension.toUpperCase()
  if (LinkHelpers.isFile(link)) return link.extension.toUpperCase()
  if (LinkHelpers.isGoogleDriveFile(link)) return link.provider_name ?? ''

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  return link.extension.toUpperCase() || getStylizedProviderName(link.provider_name) || ''
}

export function getDurationMetadataText(link: BeethovenDisplayAttributes): string {
  if (link.metadata?.duration != null) return secondsToSemiColonedText(link.metadata?.duration, true)
  return ''
}

export function getExtraMetadataText(link: BeethovenDisplayAttributes): string {
  const durationMetadataText = getDurationMetadataText(link)

  const attachmentHasDuration =
    (LinkHelpers.isSpotify(link) ||
      LinkHelpers.isYoutube(link) ||
      LinkHelpers.isAudio(link) ||
      LinkHelpers.isUploadedVideo(link)) &&
    durationMetadataText != null
  const attachmentHasFileSize = LinkHelpers.isFile(link) && link?.metadata?.pretty_size != null

  if (LinkHelpers.isPadletWall(link) || LinkHelpers.isSlideshow(link)) {
    return getStylizedProviderName(link.provider_name)
  } else if (attachmentHasDuration) {
    return durationMetadataText
  } else if (attachmentHasFileSize) {
    return link.metadata?.pretty_size as string
  }
  return ''
}

export function attachmentLoadingAnimationColorTailwindClass({
  postColor,
  isLightColorScheme,
}: {
  postColor: PostColor
  isLightColorScheme: boolean
}): string {
  switch (postColor) {
    case 'red':
      return isLightColorScheme
        ? 'animate-pulse-bg-color-slow pulse-bg-from-scarlet-200 pulse-bg-to-scarlet-400'
        : 'animate-pulse-bg-color-slow pulse-bg-from-scarlet-800 pulse-bg-to-scarlet-600'
    case 'orange':
      return isLightColorScheme
        ? 'animate-pulse-bg-color-slow pulse-bg-from-canary-200 pulse-bg-to-canary-400'
        : 'animate-pulse-bg-color-slow pulse-bg-from-tangerine-800 pulse-bg-to-tangerine-600'
    case 'green':
      return isLightColorScheme
        ? 'animate-pulse-bg-color-slow pulse-bg-from-park-200 pulse-bg-to-park-400'
        : 'animate-pulse-bg-color-slow pulse-bg-from-park-800 pulse-bg-to-park-600'
    case 'blue':
      return isLightColorScheme
        ? 'animate-pulse-bg-color-slow pulse-bg-from-oceanic-200 pulse-bg-to-oceanic-400'
        : 'animate-pulse-bg-color-slow pulse-bg-from-oceanic-800 pulse-bg-to-oceanic-600'
    case 'purple':
      return isLightColorScheme
        ? 'animate-pulse-bg-color-slow pulse-bg-from-grape-200 pulse-bg-to-grape-400'
        : 'animate-pulse-bg-color-slow pulse-bg-from-grape-800 pulse-bg-to-grape-600'
    default:
      return isLightColorScheme
        ? 'animate-pulse-bg-color-slow pulse-bg-from-grey-50 pulse-bg-to-grey-200'
        : 'animate-pulse-bg-color-slow pulse-bg-from-grey-800 pulse-bg-to-grey-600'
  }
}

export function calculateSizeToFitElementInWrapper(
  element: { width: number; height: number; aspectRatio: number },
  wrapper: { width: number; height: number },
): { width: number; height: number; isTouchingHorizontally: boolean; isTouchingVertically: boolean } {
  let width = 0
  let height = 0
  let isTouchingHorizontally = false
  let isTouchingVertically = false

  // Case 1: Element is smaller than wrapper
  // Use element's size
  if (element.width < wrapper.width && element.height < wrapper.height) {
    width = element.width
    height = element.height
    return { width, height, isTouchingHorizontally, isTouchingVertically }
  }

  // Case 2: Element has greater height than wrapper
  // Use wrapper's height and calculate width based on aspect ratio
  if (element.width < wrapper.width && element.height >= wrapper.height) {
    height = wrapper.height
    width = Math.floor(height * element.aspectRatio) // prevent issues with browser from deciding to render pixel fraction weirdly
    isTouchingVertically = true
    return { width, height, isTouchingHorizontally, isTouchingVertically }
  }

  // Case 3: Element has greater width than wrapper
  // Use wrapper's width and calculate height based on aspect ratio
  if (element.width >= wrapper.width && element.height < wrapper.height) {
    width = wrapper.width
    height = Math.floor(width / element.aspectRatio)
    isTouchingHorizontally = true
    return { width, height, isTouchingHorizontally, isTouchingVertically }
  }

  // Case 4: Element is bigger than wrapper
  const wrapperAspectRatio = wrapper.width / wrapper.height

  // Case 4.1: Element is wider than wrapper
  // Use wrapper's width and calculate height based on aspect ratio
  if (wrapperAspectRatio < element.aspectRatio) {
    width = wrapper.width
    height = Math.floor(width / element.aspectRatio)
    isTouchingHorizontally = true
  } else {
    // Case 4.2: Element is taller than wrapper
    // Use wrapper's height and calculate width based on aspect ratio
    height = wrapper.height
    width = Math.floor(height * element.aspectRatio)
    isTouchingVertically = true
  }
  return { width, height, isTouchingHorizontally, isTouchingVertically }
}

// Nitesh's idea is to allow users to download any static attachment. However, we haven't come up
// with a way for the frontend to know which attachment is static yet, so for now, only images (*)
// ,uploads, and links to pdfs can be downloaded.
// (*) Images whose content subcategory is `html` aren't static. They are images from sites like
// Pinterest. We can't download those.
export function isAttachmentDownloadable(displayAttributes: BeethovenDisplayAttributes | null): boolean {
  return (
    displayAttributes?.provider_name?.toLowerCase() === 'padlet drive' ||
    (displayAttributes?.content_category === 'photo' &&
      displayAttributes?.content_subcategory?.toLowerCase() !== 'html') ||
    (displayAttributes?.content_category === 'document' &&
      displayAttributes?.content_subcategory?.toLowerCase() === 'pdf')
  )
}

export enum StaticAttachmentType {
  Image,
  Pdf,
  Unknown,
}
export function getStaticAttachmentType(displayAttributes: BeethovenDisplayAttributes | null): StaticAttachmentType {
  if (
    displayAttributes?.content_category === 'photo' &&
    displayAttributes?.content_subcategory?.toLowerCase() !== 'html'
  ) {
    return StaticAttachmentType.Image
  }

  if (
    displayAttributes?.content_category === 'document' &&
    displayAttributes?.content_subcategory?.toLowerCase() === 'pdf'
  ) {
    return StaticAttachmentType.Pdf
  }

  return StaticAttachmentType.Unknown
}

export function handleIframeLoad(iframeElement: HTMLIFrameElement | null, onLoad: Function): void {
  // The iframe may have already loaded before we can add a handler for the load event
  // This would cause the attachment to load endlessly, thus we do a preemptive check here
  const isIframeReady = iframeElement?.contentWindow?.document.readyState === 'complete'
  if (iframeElement == null || isIframeReady) {
    onLoad()
    return
  }

  iframeElement.onload = () => {
    onLoad()
  }
}

export function shouldDisplayMetadataTag(displayAttributes: BeethovenDisplayAttributes): boolean {
  const linkForValidation: BeethovenLinkForValidation = {
    content_category: displayAttributes.content_category,
    extension: displayAttributes.extension,
    content_subcategory: displayAttributes.content_subcategory,
    provider_name: displayAttributes.provider_name,
  }
  if (LinkHelpers.isInstagram(linkForValidation)) return true
  return !LinkHelpers.isPhoto(linkForValidation)
}

export function isUnsupportedAttachmentOnZoom(
  contentCategory: BeethovenContentCategory | null,
  providerName: string,
): boolean {
  return device.zoomApp && isAttachmentSupportedByZoom({ contentCategory, providerName })
}
