/**
 * @file texts and css class names for post color values
 */
import type { RGB, RGBA } from '@@/bits/color'
import { computeLuminanceRatio, rgba, serializeRgbaStringToRgba } from '@@/bits/color'
import { __ } from '@@/bits/intl'
import { OzBoxColors } from '@@/library/v4/components/OzBox.vue'
import type { PostColor } from '@@/types'

function postColorText({
  postColor,
  isLightColorScheme,
}: {
  postColor: PostColor | 'default'
  isLightColorScheme: boolean
}): string {
  switch (postColor) {
    case 'red':
      return __('Red')
    case 'orange':
      return isLightColorScheme ? __('Yellow') : __('Orange')
    case 'green':
      return __('Green')
    case 'blue':
      return __('Blue')
    case 'purple':
      return __('Purple')
    default:
      return isLightColorScheme ? __('White') : __('Black')
  }
}

// dark/light post color depends on the wall's colorScheme setting, not user's browser color scheme
function oldPostColorTailwindClass({
  postColor,
  isLightColorScheme,
}: {
  postColor: PostColor
  isLightColorScheme: boolean
}): string {
  switch (postColor) {
    case 'red':
      return isLightColorScheme ? 'bg-old-post-red' : 'bg-old-post-red-dark'
    case 'orange':
      return isLightColorScheme ? 'bg-old-post-orange' : 'bg-old-post-orange-dark'
    case 'green':
      return isLightColorScheme ? 'bg-old-post-green' : 'bg-old-post-green-dark'
    case 'blue':
      return isLightColorScheme ? 'bg-old-post-blue' : 'bg-old-post-blue-dark'
    case 'purple':
      return isLightColorScheme ? 'bg-old-post-purple' : 'bg-old-post-purple-dark'
    default:
      return isLightColorScheme ? 'bg-old-post-light' : 'bg-old-post-dark'
  }
}

function postColorPickerBackgroundColorTailwindClass({
  postColor,
  isLightColorScheme,
}: {
  postColor: PostColor
  isLightColorScheme: boolean
}): string {
  switch (postColor) {
    case 'red':
      return 'bg-scarlet-500'
    case 'orange':
      return 'bg-canary-500'
    case 'green':
      return 'bg-park-500'
    case 'blue':
      return 'bg-oceanic-500'
    case 'purple':
      return 'bg-grape-500'
    default:
      return isLightColorScheme ? 'bg-white' : 'bg-grey-900'
  }
}

function postColorPickerRingColorTailwindClasses({
  postColor,
  isLightColorScheme,
}: {
  postColor: PostColor
  isLightColorScheme: boolean
}): string[] {
  return postColor == null
    ? ['ring-1', 'ring-inset', isLightColorScheme ? 'ring-dark-ui-stroke' : 'ring-light-ui-stroke']
    : []
}

function postColorPickerFocusRingColorTailwindClass({
  postColor,
  isLightColorScheme,
}: {
  postColor: PostColor
  isLightColorScheme: boolean
}): string {
  switch (postColor) {
    case 'red':
      return 'focus-visible:ring-grape-500 dark:focus-visible:ring-canary-500'
    case 'orange':
      return 'focus-visible:ring-grape-500'
    case 'green':
      return 'focus-visible:ring-grape-500 dark:focus-visible:ring-canary-500'
    case 'blue':
      return 'focus-visible:ring-grape-500 dark:focus-visible:ring-canary-500'
    case 'purple':
      return 'focus-visible:ring-canary-500'
    default:
      return 'focus-visible:ring-grape-500'
  }
}

function postColorPickerSelectedRingColorTailwindClasses({
  postColor,
  isLightColorScheme,
}: {
  postColor: PostColor
  isLightColorScheme: boolean
}): string[] {
  switch (postColor) {
    case 'red':
      return ['ring-2', 'ring-grape-500', 'dark:ring-canary-500']
    case 'orange':
      return ['ring-2', 'ring-grape-500']
    case 'green':
      return ['ring-2', 'ring-grape-500', 'dark:ring-canary-500']
    case 'blue':
      return ['ring-2', 'ring-grape-500', 'dark:ring-canary-500']
    case 'purple':
      return ['ring-2', 'ring-canary-500']
    default:
      return ['ring-2', 'ring-grape-500']
  }
}

function postColorRingOffsetTailwindClass({
  postColor,
  isLightColorScheme,
}: {
  postColor: PostColor
  isLightColorScheme: boolean
}): string {
  switch (postColor) {
    case 'red':
      return isLightColorScheme ? 'ring-offset-scarlet-100' : 'ring-offset-scarlet-900'
    case 'orange':
      return isLightColorScheme ? 'ring-offset-canary-100' : 'ring-offset-tangerine-900'
    case 'green':
      return isLightColorScheme ? 'ring-offset-park-100' : 'ring-offset-park-900'
    case 'blue':
      return isLightColorScheme ? 'ring-offset-oceanic-100' : 'ring-offset-oceanic-900'
    case 'purple':
      return isLightColorScheme ? 'ring-offset-grape-100' : 'ring-offset-grape-900'
    default:
      return isLightColorScheme ? 'ring-offset-light-ui-100' : 'ring-offset-dark-ui-100'
  }
}

function postColorPickerCheckmarkColorTailwindClasses({
  postColor,
  isLightColorScheme,
}: {
  postColor: PostColor
  isLightColorScheme: boolean
}): string {
  switch (postColor) {
    case 'red':
      return 'text-grape-500 dark:text-canary-500'
    case 'orange':
      return 'text-grape-500'
    case 'green':
      return 'text-grape-500 dark:text-canary-500'
    case 'blue':
      return 'text-grape-500 dark:text-canary-500'
    case 'purple':
      return 'text-canary-500'
    default:
      return 'text-grape-500'
  }
}

function postColorPickerClasses(color: PostColor, isLightColorScheme: boolean): string[] {
  return [
    postColorPickerBackgroundColorTailwindClass({ postColor: color, isLightColorScheme }),
    ...postColorPickerRingColorTailwindClasses({ postColor: color, isLightColorScheme }),
  ]
}

function postColorTailwindClass({
  postColor,
  isLightColorScheme,
}: {
  postColor: PostColor
  isLightColorScheme: boolean
}): string {
  switch (postColor) {
    case 'red':
      return isLightColorScheme ? 'bg-scarlet-100' : 'bg-scarlet-900'
    case 'orange':
      return isLightColorScheme ? 'bg-canary-100' : 'bg-tangerine-900'
    case 'green':
      return isLightColorScheme ? 'bg-park-100' : 'bg-park-900'
    case 'blue':
      return isLightColorScheme ? 'bg-oceanic-100' : 'bg-oceanic-900'
    case 'purple':
      return isLightColorScheme ? 'bg-grape-100' : 'bg-grape-900'
    default:
      return isLightColorScheme ? 'bg-light-ui-100' : 'bg-dark-ui-100'
  }
}

function postBannerBackgroundColorTailwindClass({
  postColor,
  isLightColorScheme,
}: {
  postColor: PostColor
  isLightColorScheme: boolean
}): string {
  switch (postColor) {
    case 'red':
      return isLightColorScheme ? 'bg-scarlet-200' : 'bg-scarlet-800'
    case 'orange':
      return isLightColorScheme ? 'bg-canary-200' : 'bg-tangerine-800'
    case 'green':
      return isLightColorScheme ? 'bg-park-200' : 'bg-park-800'
    case 'blue':
      return isLightColorScheme ? 'bg-oceanic-200' : 'bg-oceanic-800'
    case 'purple':
      return isLightColorScheme ? 'bg-grape-200' : 'bg-grape-800'
    default:
      return isLightColorScheme ? 'bg-light-ui-200' : 'bg-dark-ui-200'
  }
}

function toOzBoxColor(color: PostColor): OzBoxColors {
  return {
    default: OzBoxColors.PostDefault,
    red: OzBoxColors.PostRed,
    orange: OzBoxColors.PostOrange,
    green: OzBoxColors.PostGreen,
    blue: OzBoxColors.PostBlue,
    purple: OzBoxColors.PostPurple,
  }[color ?? 'default']
}

/*
 * POLLS
 */

interface PostColorFnParams {
  postColor: PostColor
  isLightColorScheme: boolean
}
type PostColorMapperFn = (postColorParams: PostColorFnParams) => string
interface PollTailwindClasses {
  pollChoiceHoverBackground: Record<'light' | 'dark' | 'auto', string>
  closedPollChoicePercentageBackground: Record<'light' | 'dark' | 'auto', string>
  pollWinnerIconText: Record<'light' | 'dark' | 'auto', string>
}

function pollTailwindClasses(postColor: PostColor): PollTailwindClasses {
  return {
    pollChoiceHoverBackground: {
      light: pollChoiceHoverBackgroundColorTailwindClass({ postColor, isLightColorScheme: true }),
      dark: pollChoiceHoverBackgroundColorTailwindClass({ postColor, isLightColorScheme: false }),
      auto: autoDarkModeTailwindClasses({ postColor, postColorMapperFn: pollChoiceHoverBackgroundColorTailwindClass }),
    },
    closedPollChoicePercentageBackground: {
      light: closedPollChoicePercentageBackgroundColorTailwindClass({ postColor, isLightColorScheme: true }),
      dark: closedPollChoicePercentageBackgroundColorTailwindClass({ postColor, isLightColorScheme: false }),
      auto: autoDarkModeTailwindClasses({
        postColor,
        postColorMapperFn: closedPollChoicePercentageBackgroundColorTailwindClass,
      }),
    },
    pollWinnerIconText: {
      light: pollWinnerIconTextColorTailwindClass({ postColor, isLightColorScheme: true }),
      dark: pollWinnerIconTextColorTailwindClass({ postColor, isLightColorScheme: false }),
      auto: autoDarkModeTailwindClasses({ postColor, postColorMapperFn: pollWinnerIconTextColorTailwindClass }),
    },
  }
}

function pollChoiceHoverBackgroundColorTailwindClass({ postColor, isLightColorScheme }: PostColorFnParams): string {
  switch (postColor) {
    case 'red':
      return isLightColorScheme ? 'hhover:bg-scarlet-300' : 'hhover:bg-scarlet-700'
    case 'orange':
      return isLightColorScheme ? 'hhover:bg-canary-300' : 'hhover:bg-tangerine-700'
    case 'green':
      return isLightColorScheme ? 'hhover:bg-park-300' : 'hhover:bg-park-700'
    case 'blue':
      return isLightColorScheme ? 'hhover:bg-oceanic-300' : 'hhover:bg-oceanic-700'
    case 'purple':
      return isLightColorScheme ? 'hhover:bg-grape-300' : 'hhover:bg-grape-700'
    default:
      return isLightColorScheme ? 'hhover:bg-grey-100' : 'hhover:bg-grey-700'
  }
}

function closedPollChoicePercentageBackgroundColorTailwindClass({
  postColor,
  isLightColorScheme,
}: PostColorFnParams): string {
  switch (postColor) {
    case 'red':
      return isLightColorScheme ? 'bg-scarlet-500' : 'bg-scarlet-300'
    case 'orange':
      return isLightColorScheme ? 'bg-canary-500' : 'bg-tangerine-300'
    case 'green':
      return isLightColorScheme ? 'bg-park-500' : 'bg-park-300'
    case 'blue':
      return isLightColorScheme ? 'bg-oceanic-500' : 'bg-oceanic-300'
    case 'purple':
      return isLightColorScheme ? 'bg-grape-500' : 'bg-grape-300'
    default:
      return isLightColorScheme ? 'bg-grape-500' : 'bg-canary-500'
  }
}

function pollWinnerIconTextColorTailwindClass({ postColor, isLightColorScheme }: PostColorFnParams): string {
  switch (postColor) {
    case 'red':
      return isLightColorScheme ? 'text-scarlet-500' : 'text-scarlet-300'
    case 'orange':
      return isLightColorScheme ? 'text-canary-500' : 'text-tangerine-300'
    case 'green':
      return isLightColorScheme ? 'text-park-500' : 'text-park-300'
    case 'blue':
      return isLightColorScheme ? 'text-oceanic-500' : 'text-oceanic-300'
    case 'purple':
      return isLightColorScheme ? 'text-grape-500' : 'text-grape-300'
    default:
      return isLightColorScheme ? 'text-grape-500' : 'text-canary-500'
  }
}

function autoDarkModeTailwindClasses({
  postColor,
  postColorMapperFn,
}: {
  postColor: PostColor
  postColorMapperFn: PostColorMapperFn
}): string {
  return `${postColorMapperFn({ postColor, isLightColorScheme: true })} dark:${postColorMapperFn({
    postColor,
    isLightColorScheme: false,
  })}`
}

/*
 * CONSTANTS
 */

const postColorValues: PostColor[] = [null, 'red', 'orange', 'green', 'blue', 'purple']
const POST_COLOR_STRINGS = [
  'default', // corresponds to post color value = null
  'red',
  'orange',
  'green',
  'blue',
  'purple',
] as const

const OLD_POST_RED = rgba(255, 205, 210, 1) // #FFCDD2
const OLD_POST_RED_DARK = rgba(183, 28, 28, 1) // #B71C1C
const OLD_POST_ORANGE = rgba(255, 236, 179, 1) // #FFECB3
const OLD_POST_ORANGE_DARK = rgba(255, 111, 0, 1) // #FF6F00
const OLD_POST_GREEN = rgba(200, 230, 201, 1) // #C8E6C9
const OLD_POST_GREEN_DARK = rgba(27, 94, 32, 1) // #1B5E20
const OLD_POST_BLUE = rgba(179, 229, 252, 1) // #B3E5FC
const OLD_POST_BLUE_DARK = rgba(13, 71, 161, 1) // #0D47A1
const OLD_POST_PURPLE = rgba(225, 190, 231, 1) // #E1BEE7
const OLD_POST_PURPLE_DARK = rgba(74, 20, 140, 1) // #4A148C
const OLD_POST_LIGHT = rgba(255, 255, 255, 1) // #FFFFFF
const OLD_POST_DARK = rgba(33, 33, 33, 1) // #212121

const POST_RED = rgba(255, 218, 218, 1.0) // #FFDADA
const POST_RED_DARK = rgba(124, 0, 0, 1.0) // #7C0000
const POST_ORANGE = rgba(255, 244, 206, 1.0) // #FFF4CE
const POST_ORANGE_DARK = rgba(145, 61, 0, 1.0) // #913D00
const POST_GREEN = rgba(222, 255, 221, 1.0) // #DEFFDD
const POST_GREEN_DARK = rgba(0, 61, 0, 1.0) // #003D00
const POST_BLUE = rgba(187, 234, 255, 1.0) // #BBEAFF
const POST_BLUE_DARK = rgba(1, 50, 107, 1.0) // #01326B
const POST_PURPLE = rgba(238, 216, 255, 1.0) // #EED8FF
const POST_PURPLE_DARK = rgba(60, 0, 98, 1.0) // #3D0062
const POST_LIGHT = rgba(255, 255, 255, 1.0) // #FFFFFF
const POST_DARK = rgba(17, 17, 17, 1.0) // #111111

const POST_APPROVAL_RED = rgba(255, 198, 203, 1.0) // #FFC6CB
const POST_APPROVAL_RED_DARK = rgba(152, 0, 0, 1.0) // #980000
const POST_APPROVAL_ORANGE = rgba(255, 235, 164, 1.0) // #FFEBA4
const POST_APPROVAL_ORANGE_DARK = rgba(187, 79, 0, 1.0) // #BB4F00
const POST_APPROVAL_GREEN = rgba(181, 255, 184, 1.0) // #B5FFB8
const POST_APPROVAL_GREEN_DARK = rgba(0, 78, 0, 1.0) // #004E00
const POST_APPROVAL_BLUE = rgba(154, 221, 251, 1.0) // #9ADDFB
const POST_APPROVAL_BLUE_DARK = rgba(0, 65, 142, 1.0) // #00418E
const POST_APPROVAL_PURPLE = rgba(231, 191, 255, 1.0) // #E7BFFF
const POST_APPROVAL_PURPLE_DARK = rgba(82, 0, 133, 1.0) // #520085
const POST_APPROVAL_LIGHT = rgba(245, 246, 247, 1.0) // #F5F6F7
const POST_APPROVAL_DARK = rgba(36, 37, 39, 1.0) // #242527

const DARK_UI_100 = rgba(17, 17, 17, 1) // #111111
const LIGHT_UI_100 = rgba(255, 255, 255, 1) // #FFFFFF
const DARK_UI_200 = rgba(33, 34, 36, 1) // #212224
const LIGHT_UI_200 = rgba(246, 246, 246, 1) // #F6F6F6

function oldPostColorRgb({
  postColor,
  isLightColorScheme,
}: {
  postColor: PostColor
  isLightColorScheme: boolean
}): RGB {
  switch (postColor) {
    case 'red':
      return isLightColorScheme ? OLD_POST_RED : OLD_POST_RED_DARK
    case 'orange':
      return isLightColorScheme ? OLD_POST_ORANGE : OLD_POST_ORANGE_DARK
    case 'green':
      return isLightColorScheme ? OLD_POST_GREEN : OLD_POST_GREEN_DARK
    case 'blue':
      return isLightColorScheme ? OLD_POST_BLUE : OLD_POST_BLUE_DARK
    case 'purple':
      return isLightColorScheme ? OLD_POST_PURPLE : OLD_POST_PURPLE_DARK
    default:
      return isLightColorScheme ? OLD_POST_LIGHT : OLD_POST_DARK
  }
}

function postColorRgb({ postColor, isLightColorScheme }: { postColor: PostColor; isLightColorScheme: boolean }): RGB {
  switch (postColor) {
    case 'red':
      return isLightColorScheme ? POST_RED : POST_RED_DARK
    case 'orange':
      return isLightColorScheme ? POST_ORANGE : POST_ORANGE_DARK
    case 'green':
      return isLightColorScheme ? POST_GREEN : POST_GREEN_DARK
    case 'blue':
      return isLightColorScheme ? POST_BLUE : POST_BLUE_DARK
    case 'purple':
      return isLightColorScheme ? POST_PURPLE : POST_PURPLE_DARK
    default:
      return isLightColorScheme ? POST_LIGHT : POST_DARK
  }
}

function postApprovalColorRgb({
  postColor,
  isLightColorScheme,
}: {
  postColor: PostColor
  isLightColorScheme: boolean
}): RGBA {
  switch (postColor) {
    case 'red':
      return isLightColorScheme ? POST_APPROVAL_RED : POST_APPROVAL_RED_DARK
    case 'orange':
      return isLightColorScheme ? POST_APPROVAL_ORANGE : POST_APPROVAL_ORANGE_DARK
    case 'green':
      return isLightColorScheme ? POST_APPROVAL_GREEN : POST_APPROVAL_GREEN_DARK
    case 'blue':
      return isLightColorScheme ? POST_APPROVAL_BLUE : POST_APPROVAL_BLUE_DARK
    case 'purple':
      return isLightColorScheme ? POST_APPROVAL_PURPLE : POST_APPROVAL_PURPLE_DARK
    default:
      return isLightColorScheme ? POST_APPROVAL_LIGHT : POST_APPROVAL_DARK
  }
}

export {
  DARK_UI_100,
  DARK_UI_200,
  LIGHT_UI_100,
  LIGHT_UI_200,
  POST_COLOR_STRINGS,
  autoDarkModeTailwindClasses,
  computeLuminanceRatio,
  oldPostColorRgb,
  oldPostColorTailwindClass,
  pollTailwindClasses,
  postApprovalColorRgb,
  postBannerBackgroundColorTailwindClass,
  postColorPickerBackgroundColorTailwindClass,
  postColorPickerCheckmarkColorTailwindClasses,
  postColorPickerClasses,
  postColorPickerFocusRingColorTailwindClass,
  postColorPickerRingColorTailwindClasses,
  postColorPickerSelectedRingColorTailwindClasses,
  postColorRingOffsetTailwindClass,
  postColorRgb,
  postColorTailwindClass,
  postColorText,
  postColorValues,
  serializeRgbaStringToRgba,
  toOzBoxColor,
}
export type { PollTailwindClasses, RGB, RGBA }
