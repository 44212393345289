// @file helpers for handling time
import { getHyphenatedCurrentLocale } from '@@/bits/current_locale'
import { captureException } from '@@/bits/error_tracker'
import type { DateDisplayFormat } from '@@/types'

/**
 * Converts seconds to time format string
 * @example if you input 71, output would be 01:11
 * @param seconds
 * @param shouldRoundSeconds
 * @returns {string} - formatted time string
 */
const secondsToSemiColonedText = function (seconds: number, shouldRoundSeconds: boolean = false): string {
  const totalMinutes = Math.floor(seconds / 60)
  const leftoverSeconds = shouldRoundSeconds ? Math.round(seconds % 60) : seconds % 60
  const minutesText = totalMinutes < 10 ? '0' + totalMinutes : '' + totalMinutes
  const secondsText = leftoverSeconds < 10 ? '0' + leftoverSeconds : '' + leftoverSeconds
  return `${minutesText}:${secondsText}`
}

function getDateStringForDisplay(datetime: string | number | Date): string {
  const date = new Date(datetime)
  let formattedTime = date.toLocaleString(undefined, {
    hour: 'numeric',
    minute: 'numeric',
  })
  let formattedDate = date.toLocaleString(undefined, {
    year: 'numeric',
    day: 'numeric',
    month: 'long',
  })
  try {
    formattedTime = date.toLocaleString(getHyphenatedCurrentLocale(), {
      hour: 'numeric',
      minute: 'numeric',
    })
    formattedDate = date.toLocaleString(getHyphenatedCurrentLocale(), {
      year: 'numeric',
      day: 'numeric',
      month: 'long',
    })
  } catch (e) {
    captureException(e, {
      context: {
        source: 'getDateStringForDisplay',
        locale: getHyphenatedCurrentLocale(),
      },
    })
  }
  return `${formattedTime} • ${formattedDate}`
}

function getCustomDateFieldStringForDisplay({
  dateDisplayFormat,
  showTime,
  datetime,
  locale = getHyphenatedCurrentLocale(),
  timezone,
}: {
  dateDisplayFormat: DateDisplayFormat
  showTime: boolean
  datetime?: string | number | Date
  locale?: string
  timezone?: string
}): string {
  const date = datetime != null ? new Date(datetime) : new Date()
  const mapping: Record<DateDisplayFormat, string> = {
    local: date.toLocaleDateString(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: showTime ? '2-digit' : undefined,
      minute: showTime ? '2-digit' : undefined,
      timeZone: timezone,
    }),
    friendly: date.toLocaleDateString(locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: showTime ? '2-digit' : undefined,
      minute: showTime ? '2-digit' : undefined,
      timeZone: timezone,
    }),
    mmddyy: date.toLocaleDateString('en-us', {
      // we choose en-us because it uses mm/dd/yyyy
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: showTime ? '2-digit' : undefined,
      hourCycle: showTime ? 'h24' : undefined,
      minute: showTime ? '2-digit' : undefined,
      timeZone: timezone,
    }),
    ddmmyy: date.toLocaleDateString('en-gb', {
      // we choose en-gb because it uses dd/mm/yyyy
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: showTime ? '2-digit' : undefined,
      hourCycle: showTime ? 'h24' : undefined,
      minute: showTime ? '2-digit' : undefined,
      timeZone: timezone,
    }),
    iso: showTime
      ? date.toISOString().slice(0, 10) +
        ' ' +
        date.toLocaleTimeString(undefined, {
          hour: '2-digit',
          minute: '2-digit',
          timeZone: timezone,
        })
      : date.toISOString().slice(0, 10), // we only need first 10 characters of the ISO string e.g. 2021-08-12
    unix: Math.floor(date.getTime() / 1000).toString(),
  }
  return mapping[dateDisplayFormat]
}

export { getCustomDateFieldStringForDisplay, getDateStringForDisplay, secondsToSemiColonedText }
